import React, { Component, useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import { Button } from "../Library/Module";
import { Animated } from "react-animated-css";
import SliderImage1 from '../images/banner2.jpg'
import SliderImage2 from '../images/slider2.jpg'
import SliderImage3 from '../images/slider3.jpg'
import SliderImage4 from '../images/slider4.jpg'
import SliderImage5 from '../images/slider5.jpg'
import SliderImage6 from '../images/slider6.jpg'
import { CMSByType } from "../servies/services";
import { toast } from "react-toastify";
import { ThemeContext } from "../Context/Theme/Context";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const SimpleSlider = () => {
    const { sliderList } = useContext(ThemeContext)
    return (
        <div className="sliderMain">
            <div className="imageSlider2">
            </div>
            {/* <Slider {...settings}>
                {sliderList?.map((item: any) => {
                    return (
                        <div>
                            <div className="imageSlider" style={{ background: `url(${SliderImage1}) center top no-repeat` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="sliderInfo">
                                                <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                    <h3 dangerouslySetInnerHTML={{ __html: item?.title }}></h3>
                                                </Animated>
                                                <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                    <p>{item?.short_description}</p>
                                                </Animated>
                                                <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                    <Button className="btn btn-primary">Enquiry Now</Button>
                                                </Animated>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider> */}
        </div>

    );
}

export default SimpleSlider